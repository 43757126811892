<template>
  <div>
    <h3 class="title-g">备注页</h3>
    <el-row>
      <el-col :span="8" class="colstyle">
        <h3>审核</h3>
        <p>审核拒绝时，描述明确具体的拒绝理由。</p>
        <p>核对统一代码与营业执照一致</p>
        <div>商家后台更改手机号，要求提供有公章的证明材料，图片格式。发给客服。后台操作时，上传证明材料。材料格式：</div>
        <div>致：十万八</div>
        <div>现要求更改xxx公司（营业执照上公司名称）的管理员手机号为13811112222.特此。</div>
        <div>公司名称、公章和日期</div>
      </el-col>

      <el-col :span="8" class="colstyle line">
        <h3>运营检查</h3>
        <p>公众号运营账户余额</p>
        <p>阿里云账户余额</p>
        <p>阿里云短信账户余量</p>
        <p>更新证书时需要更新server/storage/中的证书</p>
      </el-col>
      <el-col :span="8" class="colstyle">
        <h3>重启</h3>
        <p>重启后检查redis内存是否正确，队列需要重启：</p>
        <p>cd /www/wwwroot/server.solar960.com/server</p>
        <p>nohup php artisan queue:work --daemon >> queue.log 2>&1 &</p>
        <p>服务器升级 yum update -y</p>
      </el-col>
    </el-row>
    <el-divider>规则</el-divider>
    <el-row>
      <el-col :span="8" class="colstyle">
        <h3>注册</h3>
        <p>同一个微信号可以注册多个商家。</p>
        <p>一个营业执照只能注册一家</p>
        <p>商家注册后公司简称不能修改</p>
        <p>一个微信号在有注册进行中商家时，不能再注册第二个。审核通过后，才能开始注册第二个。</p>
      </el-col>

      <el-col :span="8" class="colstyle line">
        <h3>提现</h3>
        <p>订单完成后，48小时可以提现。18:00以前提现，24小时到账。否则48小时到账。单日提现不能超过5000元，否则顺延至第二日。</p>
      </el-col>
      <el-col :span="8" class="colstyle">
        <h3>商家</h3>
        <p>更换手机号后，管理员微信解除绑定。新手机号必须通过手机号登陆，登陆时绑定登陆时的微信号为管理员微信。只能电脑端操作</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.colstyle {
  padding: 10px 40px;
}
.line {
  border-right: 1px dashed #eee;
  border-left: 1px dashed #eee;
}
</style>